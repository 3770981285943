import React from 'react'
import './Hero.css'
import {HiLocationMarker} from 'react-icons/hi'
import CountUp from 'react-countup'
import {motion} from 'framer-motion'

const Hero = () => {
  return (
    <section className="hero-wrapper">
        <div className="paddings innerWidth flexCenter hero-container">


{/* left Side */}
            <div className="flexColStart hero-left">
                <div className="hero-title">
                    <div className="orange-circle"></div>
                    <motion.h1
                    initial={{y: "2rem", opacity: 0}}
                    animate={{y: 0, opacity: 1}}
                    transition={{
                        duration: 2,
                        type: "spring"

                    }}
                    >
                        Portfolio<br/>
                        Andi Qorri<br /> 
                        Jobs
                    </motion.h1>
                </div>

                <div className="flexColStart hero-des">
                    <span className='secondaryText'>Portfolios are everything, promises are nothing. Do the work.</span>
                    <span className='secondaryText'>I'm looking to expand my portfolio while I'm on top and while I'm young.</span>
                </div>

                <div className="flexCenter search-bar">
                    <HiLocationMarker color="var(--blue)" size={25}/>
                    <input type="text" />
                    <button className='button'>Search</button>
                </div>

                    <div className="flexCenter stats">
                        <div className="flexColCenter stat">
                            <span>
                                <CountUp start={8800} end={9000} duration={4}/>
                            <span>+</span>
                            </span> 
                            <span className='secondaryText'>Premium Products</span>
                        </div>

                        <div className="flexColCenter stat">
                            <span>
                                <CountUp start={1950} end={2000} duration={4}/>
                            <span>+</span>
                            </span> 
                            <span className='secondaryText'>Happy Costumers</span>
                        </div>

                        <div className="flexColCenter stat">
                            <span>
                                <CountUp end={28}/>
                            <span>+</span>
                            </span> 
                            <span className='secondaryText'>Award Winning</span>
                        </div>

                    </div>

            </div>




{/* Rigt Side  */}
            <div className="flexCenter hero-right">
                <motion.div 
                initial={{x: "7rem", opacity: 0}}
                animate={{x: 0, opacity: 1}}
                transition={{
                    duration: 2,
                    type: "spring"
                }}
                className="image-container">
                    <img src="./image/andi.jpg" alt="" />
                </motion.div>
            </div>
        </div>
    </section>
  )
}

export default Hero